<template>
  <div class="pb-10">
    <div v-if="loading">
      <Loading />
    </div>
    <div v-else-if="order" class="lg:w-320 mx-auto min-h-screen">
      <div class="w-full text-sm" id="print-wrapper">
        <div class="bg-white shadow overflow-hidden rounded relative">
          <button class="absolute left-1 top-0 mt-2" @click="printZebra()">
            <img class="h-10" src="/images/static/zebra.png" />
          </button>
          <button @click="fetchData" class="w-full text-center bg-primary text-white">
            <h2>#{{ order.orderNumber }}</h2>
          </button>
          <button class="absolute right-1 top-0 text-3xl text-white" @click="handleLock()">
            <span v-if="order.lock">
              <font-awesome-icon icon="lock" />
            </span>
            <span v-else>
              <font-awesome-icon icon="unlock" />
            </span>
          </button>
          <!-- Print Button -->

          <div class="grid md:grid-cols-3 gap-4 p-4 pb-2">
            <!-- Col 1 -->
            <div>
              <div class="flex">
                <div class="w-32">Order</div>
                <div class="text-primary-dark font-semibold">#{{ order.orderNumber }}</div>
              </div>
              <div class="flex my-2">
                <div class="w-32">Name</div>
                <div class="text-primary-dark font-semibold">{{ order.name }}</div>
              </div>
              <div class="flex my-2">
                <div class="w-32">Email</div>
                <div class="text-primary-dark font-semibold">{{ order.email }}</div>
              </div>
              <div class="flex my-2">
                <div class="w-32">Order Date</div>
                <div class="text-primary-dark font-semibold" v-if="date">
                  {{ `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` }}
                </div>
              </div>
            </div>

            <!-- Col 2 -->
            <div>
              <div class="flex items-center" v-if="order.orderName">
                <div class="w-32">Order Name</div>
                <div class="text-primary-dark font-semibold" v-if="order.locked">
                  {{ order.orderName }}
                </div>
                <div class="text-primary-dark font-semibold" v-else>
                  <input type="text" class="styled" v-model="order.orderName" />
                </div>
              </div>
              <div class="flex items-center" v-if="order.purchaseOrder">
                <div class="w-32">Purchase Order</div>
                <div class="text-primary-dark font-semibold" v-if="order.locked">
                  {{ order.purchaseOrder }}
                </div>
                <div class="text-primary-dark font-semibold" v-else>
                  <input type="text" class="styled" v-model="order.purchaseOrder" />
                </div>
              </div>
              <div class="flex my-2">
                <div class="w-32">Account Number</div>
                <div class="text-primary-dark font-semibold">
                  {{ order.associatedAccountNumber }}
                </div>
              </div>
              <div class="flex my-2" v-if="order.businessName">
                <div class="w-32">Business</div>
                <div class="text-primary-dark font-semibold">{{ order.businessName }}</div>
              </div>
            </div>
            <!-- Col 3 -->
            <div>
              <div class="flex">
                <div class="w-32">Order Status</div>
                <div class="font-semibold" :class="getColor(order)">
                  {{ order.status }}
                </div>
              </div>
              <div class="flex my-2" v-if="order.createdBy">
                <div class="w-32">Created By</div>
                <div class="font-semibold">
                  {{ order.createdBy }}
                </div>
              </div>
              <div class="flex my-2 items-center">
                <div class="w-32">Delivery Date</div>
                <div class="text-primary-dark font-semibold">
                  <span v-if="order.status == 'Pending'">
                    <input type="date" class="styled" v-model="deliveryDate" @change="setDate" />
                  </span>
                  <span v-else-if="deliveryDate">
                    {{ deliveryDate }}
                  </span>
                </div>
              </div>
              <div class="flex my-2 items-center">
                <div class="w-32">Delivery Method</div>
                <select
                  name="status"
                  v-model="order.shippingMethod"
                  v-if="order.status == 'Pending'"
                  @change="alterStatus()"
                  class="block pl-3 pr-10 py-1 border-gray-300 rounded-md text-sm focus:outline-none border"
                >
                  <option value="standard">USPS Standard</option>
                  <option value="ground">USPS Ground</option>
                  <option value="fedex">Fedex</option>
                  <option value="air">USPS Air</option>
                  <option value="local">Local Delivery</option>
                  <option value="call">Will Call</option>
                  <option value="dropship">Drop Ship</option>
                </select>
                <div v-else class="text-primary-dark font-semibold">
                  {{ getShippingWrapper(order.shippingMethod) }}
                </div>
              </div>
              <div class="flex" v-if="admin">
                <div class="w-32">Paid</div>
                <div class="mx-1">
                  <ToggleButton
                    color="#005785"
                    :width="60"
                    :value="order.paid"
                    :labels="{ checked: 'yes', unchecked: 'no' }"
                    :margin="3"
                    :disabled="order.lock || order.paid"
                    @change="markOrderAsPaid()"
                  />
                  <span class="text-xs text-gray-600 pl-4">
                    {{ order.paymentType }}
                    <span v-if="order.paymentType === 'Check'">#{{ order.paymentNote }}</span>
                  </span>
                </div>
              </div>
              <div class="my-2" v-if="order.status == 'Bid'">
                <button class="btn-action rounded" type="button" @click="placeOrder">
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Begin sections -->
        <div
          class="border border-gray-400 my-4 bg-white shadow relative rounded"
          v-for="(cart, index) in order.carts"
          :key="cart._id"
        >
          <LineItems
            :cart="cart"
            :index="index"
            @showAddItem="getProducts"
            @removeFromCart="removeFromCart"
            :orderNumber="order.orderNumber"
            @splitOrder="splitOrder"
            @printInvoice="printInvoice(index)"
            @printTicket="printTicket(index)"
            @deleteSection="removeSection(index)"
            :admin="admin"
            :aleteredOrder="aleteredOrder"
            :order="order"
            :locked="order.lock"
            :displayTotals="order.carts.length > 1"
            @refresh="fetchData()"
          />
        </div>

        <div class="bg-white rounded shadow p-4 mb-4" v-if="displayPay">
          <EditOrderPayment :order="order" @finished="displayPay = false" />
        </div>

        <div class="bg-white rounded shadow p-4">
          <div class="w-full flex justify-between items-center">
            <div class="h-24">
              Shipping Address:
              <div v-if="order.shippingAddress" class="text-primary-dark">
                {{ order.shippingAddress }}
                <br />
                {{ order.shippingCity }}, {{ order.shippingState }} {{ order.shippingZip }}
              </div>
            </div>
            <div class="hidden md-block" id="qr-code">
              <vue-qrcode :value="`https://pagecustomsupplyllc.com/admin/orders/${order._id}`" />
            </div>

            <div v-if="admin" class="md:w-1/2">
              <OrderTotals
                :products="setTotalProducts()"
                :discount="0"
                :shipping="order.shipping"
                :taxRate="order.taxRate"
                :storeCredit="order.storeCredit"
              />
            </div>
          </div>
          <div>
            <div class="flex md:flex-row flex-col gap-3 col-span-2 w-full px-4 md:justify-between">
              <div class="w-full">
                <div>Purchase Order Note:</div>
                <textarea v-model="order.orderNote" class="styled w-full shadow" />
              </div>
              <div class="w-full">
                <div>Customer Notes:</div>
                <textarea v-model="order.customerNote" class="styled w-full shadow" />
              </div>
              <div class="w-full">
                <div>Delivery Note:</div>
                <textarea v-model="order.deliveryNote" class="styled w-full shadow"></textarea>
              </div>
            </div>
          </div>

          <div class="w-full flex justify-between mt-8 px-4 items-center">
            <div>
              <label for="order-status" class="block text-sm font-medium text-gray-700">
                Order status
              </label>
              <select
                name="order-status"
                v-model="order.status"
                @change="alterStatus()"
                class="mt-1 block pl-3 pr-10 py-1 border-gray-300 rounded-md text-sm focus:outline-none border"
                id="order-status"
              >
                <option value="Quote">Quote</option>
                <option value="Pending">Pending</option>
                <option value="Partially Fulfilled">Partially Fulfilled</option>
                <option value="Fulfilled">Fulfilled</option>
                <option value="Delivered">Delivered</option>
                <option value="Drop Ship">DropShip</option>
                <option value="Canceled">Canceled</option>
              </select>
            </div>

            <DualButton
              :options="dualButtonOptions"
              verticalPosition="top"
              @execute="handleDualButton($event)"
              position="right"
            />
          </div>
        </div>
      </div>

      <div v-if="order.order_return" :key="order_return_key">
        <SingleOrderReturn
          :order_return="order.order_return"
          :taxRate="order.taxRate"
          :email="order.email"
          :businessName="order.businessName"
        />
      </div>

      <div class="my-2 bg-white rounded shadow p-4">
        <div class="flex justify-between">
          <h4 class="text-primary">Logs</h4>
          <button @click="showLogs = !showLogs">
            <font-awesome-icon icon="chevron-down" :class="showLogs ? 'flip' : null" />
          </button>
        </div>
        <div v-if="showLogs">
          <Entry
            v-for="(log, index) in logs"
            :key="log._id"
            :entry="log"
            class="p-2"
            :class="index % 2 ? 'bg-gray-200' : null"
          />
        </div>
      </div>

      <div class="flex w-full justify-between my-2 px-1">
        <button class="btn-white" @click="sendConfirmation()">
          <font-awesome-icon icon="share" />
          Confirmation
        </button>
        <button class="btn-white" @click="sendInvoice()">
          <font-awesome-icon icon="share" />
          Invoice
        </button>

        <navigation-controls
          @previous="previousOrder"
          @next="nextOrder"
          :previousId="previousOrderId"
          :nextId="nextOrderId"
        />
      </div>
    </div>
    <DesktopModal v-if="showAddItem" @close="showAddItem = false" :controlWidth="true">
      <Products
        class="my-2"
        @addToCart="addToCart"
        :associatedAccountNumber="order.associatedAccountNumber"
      />
    </DesktopModal>
    <DesktopModal v-if="showPaidModal" @close="showPaidModal = false" title="Payment Method">
      <div class="flex justify-between">
        <div>
          <button
            class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500"
            type="button"
            @click="setPaymentMethod('Cash')"
          >
            Cash
          </button>
        </div>
        <div>
          <button
            class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500"
            type="button"
            @click="setPaymentMethod('Credit Card')"
          >
            Credit Card
          </button>
        </div>
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type="text"
              name="check"
              id="check"
              class="focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
              placeholder="Check #"
              v-model="checkNumber"
            />
          </div>
          <button
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:border-indigo-500"
            @click="setPaymentMethod('Check')"
          >
            <span>Check</span>
          </button>
        </div>
        <div>
          <button
            class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500"
            type="button"
            @click="setPaymentMethod('In Store Credit')"
          >
            In Store Credit
          </button>
        </div>
      </div>
    </DesktopModal>
  </div>
</template>

<script>
import CartItem from '@/assets/utils/CartItem';
import Loading from '@/components/shared/Loading';
import LineItems from './EditOrderLineItems';
import { status as getColor } from '@/assets/functions/getColor.js';
import Products from './Products';
import Entry from './../logger/Entry.vue';
import PrintOrders from '@/assets/functions/printOrders.js';
import NavigationControls from '@/components/shared/NavigationControls.vue';
import DesktopModal from '@/components/shared/DesktopModal.vue';
import getShipping from '@/assets/functions/getShipping';
import VueQrcode from 'vue-qrcode';
import DualButton from '@/components/shared/DualButton';
import { ToggleButton } from 'vue-js-toggle-button';
import OrderTotals from '@/components/shared/NewOrderTotals.vue';
import SingleOrderReturn from '@/components/admin/returns/SingleReturn';
import EditOrderPayment from './EditOrderPayment.vue';
import calculateCosts from '@/assets/functions/calculateCosts';

export default {
  data: () => {
    return {
      deliveryDate: null,
      date: null,
      showAddItem: false,
      addItemsToCart: 0,
      showLogs: false,
      aleteredOrder: false,
      order_return_key: 0,
      showPaidModal: false,
      checkNumber: null,
      displayPay: false,
    };
  },
  computed: {
    dualButtonOptions() {
      const options = [
        {
          name: 'Save',
          buttonMethod: 'update',
        },
        {
          name: 'Duplicate',
          buttonMethod: 'duplicate',
        },
        {
          name: 'Begin return',
          buttonMethod: 'return',
        },
      ];

      if (!this.order.paid) {
        options.push({ name: 'Pay', buttonMethod: 'pay' });
      }

      if (
        this.order.status === 'Canceled' &&
        (this.user.email === 'pagehardware4less@gmail.com' ||
          this.user.email === 'gabrielsuttner@gmail.com')
      ) {
        options.push({
          name: 'Delete',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
          buttonMethod: 'delete',
        });
      }
      return options;
    },
    nextOrderId() {
      return this.$store.getters['editOrder/getNextOrder'];
    },
    previousOrderId() {
      return this.$store.getters['editOrder/getPreviousOrder'];
    },
    order: {
      get() {
        return this.$store.getters['editOrder/getOrder'];
      },
      set(val) {
        this.$store.commit('editOrder/setOrder', val);
      },
    },
    orderTotals() {
      return calculateCosts(
        this.setTotalProducts(),
        this.order.taxRate,
        this.order.shipping,
        this.order.discount,
        this.order.storeCredit
      );
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    loading() {
      return this.$store.getters['editOrder/getLoading'];
    },
    logs() {
      return this.$store.getters.getLogs;
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
    order() {
      this.date = new Date(this.order.date);
      if (this.order.deliveryDate) {
        if (this.order.status == 'Pending') {
          const d = new Date(this.order.deliveryDate);
          this.deliveryDate = d.toISOString().split('T')[0].slice(0, 10);
        } else {
          this.deliveryDate = new Date(this.order.deliveryDate).toUTCString().slice(0, 16);
        }
      }
    },
  },
  methods: {
    handleDualButton(option) {
      switch (option.buttonMethod) {
        case 'update':
          this.updateOrder();
          break;
        case 'duplicate':
          this.duplicateOrder();
          break;
        case 'return':
          this.beginReturn();
          break;
        case 'pay':
          this.displayPay = true;
          break;
        default:
          this.deleteOrder();
          break;
      }
    },
    beginReturn() {
      const cartMap = new Map();
      this.order.carts.forEach((el) => {
        el.cartItems.forEach((kal) => {
          const val = { ...kal };
          if (cartMap.has(val.productNum)) {
            const nVal = cartMap.get(val.productNum);
            nVal.quantity += val.quantity;
            cartMap.set(val.productNum, nVal);
          } else {
            val.fulfilled = 0;
            val._id = undefined;
            cartMap.set(val.productNum, val);
          }
        });
      });
      const cart = [...cartMap.values()];
      this.$store.commit('editOrder/setOrderReturn', {
        order_id: this.order._id,
        order_number: this.order.orderNumber,
        status: 0,
        total: 0,
        items: cart,
      });
      this.$forceUpdate();
    },
    deleteOrder() {
      this.$store.dispatch('editOrder/deleteOrder', this.order._id);
      this.$router.push('/admin/orders');
    },
    getShippingWrapper(method) {
      return getShipping(method);
    },
    softUpdate() {
      this.$store.dispatch('order/softUpdate', this.order);
    },

    async placeOrder() {
      try {
        this.order.status = 'Pending';
        await this.$store.dispatch('order/submitOrder', {
          order: this.order,
        });
        this.order.status = 'Pending';
      } catch (error) {
        this.$store.commit('setError', {
          message: 'Failed to create order',
          color: 'red',
        });
      }
    },
    setDate() {
      this.order.deliveryDate = this.deliveryDate;
    },
    fetchData() {
      this.$store.dispatch('editOrder/getOrder', this.$route.params.id);
      this.aleteredOrder = false;
    },

    getColor: getColor,
    getQrCodeImage() {
      const qrCode = document.getElementById('qr-code').childNodes;
      return qrCode[0].src;
    },
    updateOrder() {
      if (this.order.status === 'Canceled') {
        if (confirm('Are you sure you want to cancel this order?')) {
          this.$store.dispatch('editOrder/cancelOrder', this.order);
          const arr = new Array();
          this.order.qrCode = this.getQrCodeImage();
          const order = { ...this.order };

          order.cart = this.order.carts[0];
          arr.push(order);
          PrintOrders.printWaterMark(arr);
        }
      } else {
        this.$store.dispatch('editOrder/updateOrder', this.order);
      }
    },
    splitOrder(cart) {
      this.order.carts.push({
        cartItems: cart.cartItems,
        name: `${this.order.orderNumber}.${this.order.carts.length}`,
        print: 0,
        status: 'Awaiting Products',
        quickbooksId: cart.quickbooksId,
      });
    },
    printTicket(index) {
      const arr = new Array();
      this.order.qrCode = this.getQrCodeImage();
      const order = { ...this.order };
      order.cart = this.order.carts[index];
      arr.push(order);
      PrintOrders.print(arr);
      this.order.carts[index].print += 1;
      this.$store.dispatch('editOrder/updateOrder', this.order);
    },
    printInvoice(index) {
      const arr = new Array();
      this.order.qrCode = this.getQrCodeImage();
      const order = { ...this.order };
      order.cart = this.order.carts[index];
      arr.push(order);
      PrintOrders.invoice(arr);
      this.order.carts[index].print += 1;
      this.$store.dispatch('editOrder/updateOrder', this.order);
    },
    printZebra() {
      PrintOrders.printZebra([this.order]);
    },
    async duplicateOrder() {
      const response = await this.$store.dispatch('createOrder/duplicateOrder', this.order);
      this.$store.commit('createOrder/setDuplicateUser', this.order.email);
      this.$store.commit('createOrder/setDuplicateOrder', response.data.cart);
      this.$store.commit(
        'setError',
        {
          message: `Order ${this.order.orderNumber} has been successfully duplicated. The user is currently being retrieved. After the user has been retrieved, please type a letter into the product search bar in the products section of this page.`,
          color: 'green',
          duration: 0,
        },
        { root: true }
      );
      this.$router.push({
        name: 'Create Order',
      });
    },
    addToCart(variant) {
      let title = `${variant.title} `;
      if (variant.options[0]) {
        title += ` (${variant.options[0]})`;
      }

      if (variant.options[1]) {
        title += ` (${variant.options[1]})`;
      }

      if (variant.options[2]) {
        title += ` (${variant.options[2]})`;
      }

      const cartItem = new CartItem(
        title,
        variant.image,
        variant.product_id,
        variant._id,
        1,
        0,
        variant.price,
        variant.price,
        variant.productNum,
        variant.binLocation,
        variant.quickbooksId,
        variant.vendor,
        variant.lb,
        variant.oz,
        variant.stock,
        variant.isBundle,
        variant.bundleItems,
        variant.bundleDiscountPer
      );
      this.$store.commit('editOrder/addToCart', { cartItem, index: this.addItemsToCart });
      this.$store.dispatch('createLog', {
        userEmail: this.user.email,
        type: 'Order',
        category: 'Update',
        name: `PCS-${this.order.orderNumber}`,
        description: `Added product ${title}`,
      });
      this.softUpdate();
    },

    removeFromCart(payload) {
      this.$store.commit('editOrder/removeFromCart', payload);
      this.$store.dispatch('createLog', {
        userEmail: this.user.email,
        type: 'Order',
        category: 'Update',
        name: `PCS-${this.order.orderNumber}`,
        description: `Removed ${payload.cartItem.name} from order`,
      });
      this.softUpdate();
    },
    nextOrder() {
      this.$router.push(`/admin/orders/${this.nextOrderId}`);
    },
    previousOrder() {
      this.$router.push(`/admin/orders/${this.previousOrderId}`);
    },
    getProducts(index) {
      this.addItemsToCart = index;
      this.$store.dispatch('createOrder/getUserProducts', this.order.associatedAccountNumber);
      this.showAddItem = !this.showAddItem;
    },
    alter() {
      this.aleteredOrder = true;
    },
    alterStatus() {
      this.alter();
      if (this.order.status === 'Fulfilled') {
        for (let i = 0; i < this.order.carts.length; i++) {
          if (this.order.carts[i].status != 'Fulfilled') {
            this.$store.commit('setError', {
              message:
                'You cannot mark an order as fulfilled if not all products have been fulfilled',
              color: 'red',
              duration: 0,
            });
            this.order.status = 'Partially Fulfilled';

            return;
          }
        }
      }
    },
    removeSection(index) {
      this.order.carts.splice(index, 1);
    },
    handleLock() {
      if (!this.order.carts.some((el) => el.status != 'Fulfilled')) {
        if (
          !this.order.lock &&
          confirm(
            'Are you sure you want to lock this order? Locking the order makes it so this order cannot be changed.'
          )
        ) {
          this.order.lock = true;
          this.updateOrder();
        }
      } else {
        this.$store.commit('setError', {
          message: 'Orders with unfulfilled sections cannot be locked',
          color: 'red',
        });
      }
    },
    sendConfirmation() {
      const email = prompt('What email do you want the confirmation sent to?');
      if (email) {
        this.$store.dispatch('admin/sendConfirmation', { order: this.order, email });
      }
    },
    sendInvoice() {
      const email = prompt('What email do you want the invoice sent to?');

      if (email) {
        this.$store.dispatch('admin/sendInvoice', {
          order: { ...this.order, ...this.orderTotals },
          email,
        });
      }
    },
    setTotalProducts() {
      let products = [];
      for (const cart of this.order.carts) {
        products = products.concat(cart.cartItems);
      }
      return products;
    },
    markOrderAsPaid() {
      this.showPaidModal = true;
    },
    setPaymentMethod(value) {
      this.order.paymentType = value;
      switch (value) {
        case 'Credit Card':
          break;
        case 'Check':
          if (!this.checkNumber) {
            this.$store.commit('setError', {
              message: 'Please input the check number',
              color: 'red',
              duration: 10000,
            });
            return;
          }
          this.order.paymentNote = this.checkNumber;
          break;
        default:
          break;
      }
      this.order.paid = true;
      this.order.carts.forEach((el) => (el.paid = true));
      this.showPaidModal = false;
      this.updateOrder();
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    Loading,
    LineItems,
    OrderTotals,
    NavigationControls,
    DesktopModal,
    Products,
    Entry,
    VueQrcode,
    DualButton,
    ToggleButton,
    SingleOrderReturn,
    EditOrderPayment,
  },
};
</script>
<style scoped></style>
