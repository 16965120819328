<!-- 
This component is used as the navigation button between products, orders, and users
It requires props: nextId and previousId. These are used to indicate whether or not
there is a next or previous item to go to. 

Methods previous and next are emitted on clicks. They need to be accounted for in 
the parent component
-->

<template>
  <div class="flex" @keydown.left="previous">
    <button
      class="-left-1 text-xl pl-2 pr-3 mr-1 shadow bg-white rounded-l-lg"
      :class="
        previousId === null
          ? ' bg-gray-300 text-gray-500'
          : ' hover:bg-primary-light hover:text-white'
      "
      @click="previous"
      :disabled="previousId === null"
    >
      <font-awesome-icon icon="chevron-left" />
    </button>
    <button
      class="right-0 text-xl pr-2 pl-3 ml-1 shadow bg-white rounded-r-lg"
      :class="nextId === null ? ' bg-gray-300 text-gray-500' : ' hover:bg-primary hover:text-white'"
      @click="next"
      :disabled="nextId === null"
    >
      <font-awesome-icon icon="chevron-right" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    nextId: String,
    previousId: String,
  },
  methods: {
    previous() {
      if (this.previousId) {
        this.$emit('previous');
      }
    },
    next() {
      if (this.nextId) {
        this.$emit('next');
      }
    },
  },
};
</script>

<style></style>
