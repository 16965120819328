<template>
  <div
    :class="
      status === 0
        ? `text-gray-800 ${size}`
        : status === 1
        ? `text-blue-500 ${size}`
        : status >= 5
        ? `text-gray-500 ${size}`
        : `text-green-500 ${size}`
    "
  >
    {{ returnStatus }}
  </div>
</template>

<script>
export default {
  props: {
    status: Number,
    size: { type: String, default: 'text-base' },
  },
  computed: {
    returnStatus() {
      switch (this.status) {
        case 0:
          return 'Not submitted';
        case 1:
          return 'Unprocessed';
        case 2:
          return 'Processing Return';
        case 3:
          return 'Processing Refund';
        case 5:
          return 'Partial Refund';
        case 6:
          return 'Could Not Refund';
        default:
          return 'Refund Granted';
      }
    },
  },
};
</script>

<style></style>
