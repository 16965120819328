<!--
  Takes options as a prop, options is an array of object which can contain the following:
  name: String that is displayed as the button.
  text: String that describes the button.
  bgColor: background color of the text description.
  bgHoverColor: background hover color of the text description.
  
  position: right or left
  verticalPosition: top or bottom 

  example:
  dualButtonOptions: [
    {
      name: 'Save',
      text: 'Save the product.',
    },
    {
      name: 'Delete Product',
      text: 'This action is irreversable',
      bgColor: 'bg-red-300',
      bgHoverColor: 'bg-red-600',
    },
  ],

  The first option will emit to @option_0 and so forth
-->
<template>
  <div
    class="w-full flex"
    :class="position === 'right' ? 'justify-end' : position === 'left' ? ' justify-start' : ''"
  >
    <div class="relative">
      <div class="inline-flex shadow-sm rounded-md divide-x divide-blue-600">
        <div class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-600">
          <div
            class="relative inline-flex items-center border border-transparent rounded-l-md shadow-sm text-white"
            :class="getCurrentClasses()"
          >
            <button @click="execute()" class="ml-2.5 text-sm font-medium pl-3 pr-4 py-1">
              <span v-if="currentOption.isConnected">&#10003;</span> {{ currentOption.name }}
            </button>
          </div>
          <button
            type="button"
            @click="showDropdown = !showDropdown"
            class="relative inline-flex items-center bg-primary p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-action focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
          >
            <font-awesome-icon icon="chevron-down" />
          </button>
        </div>
      </div>
      <ul
        class="absolute mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none z-1 transition-transform duration-200"
        :class="getPosition()"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-0"
        v-if="showDropdown"
      >
        <li
          v-for="option in options"
          :key="option.name"
          class="text-gray-900 hover:text-white cursor-pointer select-none relative py-4 px-2 text-sm"
          :class="getClasses(option)"
          id="listbox-option-0"
          role="option"
          @click="setButton(option)"
        >
          <div class="flex flex-col">
            <div class="flex justify-between">
              <p class="focus:font-semibold hover:text-white font-bold">
                <span :hidden="!option.isConnected">&#10003;</span> {{ option.name }}
              </p>
            </div>
            <p class="mt-1 text-left text-xs">{{ option.text }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      showDropdown: false,
      currentSelection: null,
      currentOption: { name: 'loading' },
      isConnected: false,
    };
  },
  props: {
    options: Array,
    position: { type: String, default: 'left' },
    verticalPosition: { type: String, default: 'bottom' },
    size: { type: String, default: 'medium' },
  },
  computed: {},
  watch: {},
  updated: function () {
    if (this.currentOption.name === 'Connect to Quickbooks') {
      this.currentOption = this.options[0];
    }
  },
  methods: {
    getPosition() {
      let classes = '';
      if (this.position === 'left') {
        classes = ' left-0 ';
      } else {
        classes = ' right-0 ';
      }
      if (this.verticalPosition === 'top') {
        classes += ' bottom-2 ';
      }

      return classes;
    },
    setButton(option) {
      this.currentOption = option;
      this.showDropdown = false;
    },
    execute() {
      this.$emit('execute', this.currentOption);
    },
    getCurrentClasses() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.currentOption.name === this.options[i].name) {
          const option = this.options[i];
          let text = '';
          if (option.bgColor) {
            text += option.bgColor;
          } else {
            text += ' bg-primary';
          }

          if (option.bgHoverColor) {
            text += ` hover:${option.bgHoverColor}`;
          } else {
            text += ' hover:bg-action';
          }
          return text;
        }
      }
    },
    getClasses(option) {
      let text = '';
      if (option.bgColor) {
        text += option.bgColor;
      } else {
        text += ' bg-white';
      }

      if (option.bgHoverColor) {
        text += ` hover:${option.bgHoverColor}`;
      } else {
        text += ' hover:bg-blue-500';
      }

      return text;
    },
  },
  mounted() {
    if (this.options) {
      this.currentOption = this.options[0];
    } else {
      this.currentOption = {
        isConnected: false,
        name: 'second',
      };
    }
  },
};
</script>
<style scoped></style>
