<!--
This component can have protected stuff inside of it because it is in the edit orders section and the users order section. 
-->
<template>
  <div class="border border-gray-400 my-4 bg-white shadow relative rounded p-4">
    <!-- Start of the product grid -->
    <div class="flex grid-cols-10 font-bold text-sm p-2 justify-between items-center relative">
      <div
        class="absolute -left-1 -top-1 px-1 text-red pulse text-xl rounded-full bg-black"
        v-if="order_return.status > 0 && !order_return.quickbooksId"
      >
        <font-awesome-icon icon="exclamation-triangle" />
      </div>

      <div class="col-span-5">Return for #{{ order_return.order_number }}</div>

      <OrderReturnStatus :status="order_return.status" />
    </div>

    <div class="overflow-hidden border-b border-gray-200">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              price
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              Stock
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              received/quantity
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="relative"
            v-for="(item, itemIndx) in order_return.items"
            :class="itemIndx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            :key="item._id"
          >
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <div class="flex justify-items-start">
                <div class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden self-center mr-4">
                  <img v-lazy="item.image" />
                </div>
                <button @click="handleNavigation(item.productId)" class="text-left">
                  <div>
                    {{ item.name }}
                  </div>
                  <div class="text-sm text-gray-600">{{ item.productNum }}</div>
                  <div class="text-sm text-red md:hidden">
                    <span v-if="item.fulfilled">{{ item.quantity }}</span>
                    <span v-else>_</span> / {{ item.quantity }}
                  </div>
                </button>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
              <span class="font-semibold w-20 text-center styled"> ${{ item.price }} </span>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
              <div class="flex justify-between">
                <div class="relative tooltip text-green-300">
                  <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                    At Purchase
                  </div>

                  {{ item.stock ? item.stock : 0 }}
                </div>
                <div class="relative tooltip text-primary-dark">
                  <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                    Live Inventory
                  </div>
                  {{ item.liveStock }}
                </div>
              </div>
            </td>
            <td
              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell relative"
            >
              <div class="flex items-center justify-end mr-4 col-span-2 overflow-hidden">
                <div class="border rounded-md">
                  <input
                    type="number"
                    v-model="item.fulfilled"
                    :disabled="order_return.status > 3"
                    class="w-12 text-right fulfilled-input rounded-l-md"
                    :tabindex="itemIndx"
                  />
                  /
                  <input
                    type="number"
                    v-model="item.quantity"
                    :disabled="order_return.status > 3"
                    class="w-12 text-left fulfilled-input rounded-r-md"
                    :tabindex="itemIndx + 1"
                  />
                </div>

                <span class="text-xs">
                  {{ item.pieceSize ? `(${item.pieceSize})` : '' }}
                </span>
              </div>
            </td>
            <td v-if="item.fulfilled < item.quantity && order_return.status > 4" colspan="5"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid grid-cols-2 my-4 gap-8">
      <div>
        <div class="text-sm text-gray-500">Customer Notes:</div>
        <div class="w-full h-full p-2">
          {{ order_return.customer_note }}
        </div>
        <div v-if="order_return.status > 4" class="flex flex-col myt-2">
          <label for="rejection_reason" class="text-sm text-gray-500">Reason for rejection</label>
          <textarea
            id="rejection_reason"
            placeholder="Please write down the reason for rejecting the refund. The customer will receive the reason you put here in the email that is sent to them."
            v-model="order_return.rejection_reason"
            class="styled w-full shadow h-full p-2"
          />
        </div>
      </div>
      <div>
        <label for="employee_note" class="text-sm text-gray-500">Employee Note:</label>
        <textarea
          v-model="order_return.employee_note"
          id="employee_note"
          class="styled w-full shadow"
        />
      </div>
    </div>
    <!-- End of product table -->
    <div class="flex justify-end">
      <div class="md:w-1/2">
        <OrderTotals
          :products="order_return.items"
          :taxRate="taxRate"
          :discount="0"
          :shipping="0"
          :showShipping="false"
        />
      </div>
    </div>
    <div class="flex justify-between items-center">
      <!-- v-if="order_return.status != 4 && order_return.status != 5" -->
      <div>
        <label for="refund-status" class="block text-sm font-medium text-gray-700">
          Refund status
        </label>
        <select
          v-model="order_return.status"
          id="refund-status"
          class="mt-1 block pl-3 pr-10 py-1 border-gray-300 rounded-md text-sm focus:outline-none border"
        >
          <option :value="1">Unprocessed</option>
          <option :value="2">Processing Return</option>
          <option :value="3">Processing Refund</option>
          <option :value="5">Partial Refund</option>
          <option :value="6">Could Not Refund</option>
        </select>
      </div>

      <div>
        <DualButton
          :options="dualButtonOptions"
          verticalPosition="top"
          @execute="handleDualButton($event)"
          position="right"
        />
      </div>
    </div>
    <DesktopModal v-if="showRefundModal" @close="showRefundModal = false" title="Method of refund">
      <div>
        Would you like the ${{ order_return.total }} to be returned to the credit card or return as
        in store credit?
      </div>
      <div class="flex justify-between mt-10">
        <button class="border p-2 rounded bg-white" @click="grantRefund(0)">Credit Card</button>
        <button class="border p-2 rounded bg-white" @click="grantRefund(1)">In Store Credit</button>
      </div>
    </DesktopModal>
  </div>
</template>

<script>
import OrderTotals from '@/components/shared/NewOrderTotals.vue';
import OrderReturnStatus from '@/components/shared/OrderReturnStatus.vue';
import DesktopModal from '@/components/shared/DesktopModal.vue';
import DualButton from '@/components/shared/DualButton.vue';

export default {
  props: {
    order_return: { type: Object },
    taxRate: Number,
    email: String,
    businessName: String,
  },
  data: function () {
    return {
      altered: false,
      showRefundModal: false,
    };
  },
  computed: {
    dualButtonOptions() {
      const options = [{ name: 'Save', buttonMethod: 'save' }];

      if (this.order_return.status > 1) {
        options.push({ name: 'Send "received" email', buttonMethod: 'received_email' });
      }
      if (this.order_return.status === 5) {
        options.push({ name: 'Grant partial refund', buttonMethod: 'partial_email' });
      }
      if (this.order_return.status === 6) {
        options.push({ name: 'Send "rejected" email', buttonMethod: 'reject_email' });
      }
      if (!this.order_return.quickbooksId) {
        options.push({ name: 'Grant refund', buttonMethod: 'refund' });
      }
      return options;
    },
    eligibleForRefund() {
      for (const product of this.order_return.items) {
        if (product.fulfilled < product.quantity) {
          return false;
        }
      }
      return true;
    },
    total: {
      get() {
        let cumulativeCost = 0;
        for (const product of this.order_return.items) {
          cumulativeCost += product.price * product.quantity;
        }
        cumulativeCost += cumulativeCost * this.taxRate;
        return cumulativeCost.toFixed(2);
      },
    },
  },
  watch: {},
  methods: {
    showTextArea(index) {
      const doc = document.getElementById(`return_note_${index}`);
      if (doc.classList.contains('hidden')) {
        doc.classList.remove('hidden');
      } else {
        doc.classList.add('hidden');
      }
    },
    handleNavigation(id) {
      const val = `/admin/products/${id}`;
      const routeData = this.$router.resolve({
        path: val,
      });
      window.open(routeData.href, '_blank');
    },
    quickbooks() {
      this.$store.dispatch('quickbooks/createInvoice', {
        _id: this.order._id,
        index: this.index,
      });
    },

    saveReturn() {
      if (this.order_return._id) {
        this.$store.dispatch('editOrder/saveReturn', this.order_return);
      } else {
        this.$store.dispatch('editOrder/submitReturn', this.order_return);
      }
      this.$store.commit('setError', {
        message: 'Successfully saved return.',
        color: 'green',
      });
    },
    sendEmail(type) {
      if (type >= 0 && !this.order_return.rejection_reason) {
        this.$store.commit('setError', {
          color: 'red',
          message: 'You cannot send this email without setting a reason. ',
        });
        return;
      }
      this.$store.dispatch('editOrder/sendEmail', {
        _id: this.order_return._id,
        order_return: this.order_return,
        email: this.email,
        type,
      });
    },
    openRefundModal() {
      this.order_return.status = 4;
      this.showRefundModal = true;
    },
    grantRefund(type) {
      this.order_return.repayment_type = type;
      this.order_return.total = this.total;
      this.order_return.businessName = this.businessName;
      this.$store.dispatch('editOrder/sendRefundGrantedEmail', {
        _id: this.order_return._id,
        order_return: this.order_return,
        email: this.email,
      });
      this.showRefundModal = false;
    },
    handleDualButton(option) {
      switch (option.buttonMethod) {
        case 'received_email':
          this.sendEmail();
          return;
        case 'partial_email':
          this.openRefundModal();
          this.order_return.status = 5;
          return;
        case 'reject_email':
          this.sendEmail(1);
          return;
        case 'refund':
          this.openRefundModal();
          return;
        default:
          this.saveReturn();
      }
    },
  },
  components: {
    OrderTotals,
    OrderReturnStatus,
    DesktopModal,
    DualButton,
  },
  mounted() {},
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.selected {
  border: #00a0b1 solid 1px !important;
  border-radius: 1rem;
}
</style>
