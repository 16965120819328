module.exports = (method) => {
  switch (method) {
    case 'standard':
      return 'USPS Standard';
    case 'ground':
      return 'USPS Ground';
    case 'fedex':
      return 'Fedex';
    case 'air':
      return 'USPS Air';
    case 'local':
      return 'Local Delivery';
    case 'call':
      return 'Will Call';
    default:
      return 'Drop Ship';
  }
};
