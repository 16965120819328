<template>
  <div>
    <div class="min-h-screen text-sm pb-4">
      <!-- Search -->
      <div class="flex-col sm:flex-row items-center flex relative">
        <form @submit.prevent="clearOrders()" class="w-72 relative mb-4 sm:mb-0 mr-1">
          <button class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1">
            <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
          </button>
          <input
            class="bg-white w-full rounded-full pl-12 py-2 outline-none"
            v-model="orderSearch"
            @change="clearOrders"
            placeholder="Search for orders"
            @keyup.esc="orderSearch = null"
            type="search"
            autocomplete="off"
          />
        </form>
        <div class="relative border rounded p-2 bg-white ml-auto w-32">
          <div class="flex justify-between">
            <label> Fields </label>
            <button @click="showFields = !showFields">
              <font-awesome-icon
                icon="chevron-down"
                class="transition-transform duration-200"
                :class="showFields ? 'flip' : null"
              />
            </button>
          </div>
          <div
            class="absolute ml-05 right-0 top-2 z-10 shadow-lg bg-white border rounded py-2 px-1 w-40"
            v-if="showFields"
          >
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.paymentType"
                  id="field_payment_type"
                />
              </div>
              <label for="field_payment_type" class="flex"> Payment Type </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.date"
                  id="field_date"
                />
              </div>
              <label for="field_date" class="flex"> Date </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.deliveryDate"
                  id="field_delivery"
                />
              </div>
              <label for="field_delivery" class="flex"> Delivery Date </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.total"
                  id="field_total"
                />
              </div>
              <label for="field_total" class="flex"> Total </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.status"
                  id="field_status"
                />
              </div>
              <label for="field_status" class="flex"> Fulfillment </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.shippingMethod"
                  id="field_method"
                />
              </div>
              <label for="field_method" class="flex"> Delivery Method </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.purchaseOrder"
                  id="field_purchase_order"
                />
              </div>
              <label for="field_purchase_order" class="flex"> Purchase Order </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.orderName"
                  id="field_job_name"
                />
              </div>
              <label for="field_job_name" class="flex"> Job Name </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.printed"
                  id="printed"
                />
              </div>
              <label for="printed" class="flex"> Printed </label>
            </div>
            <div class="flex">
              <div class="w-4 h-4 ml-2">
                <input
                  type="checkbox"
                  class="styled border"
                  v-model="fields.order_return"
                  id="field_returns_type"
                />
              </div>
              <label for="field_returns_type" class="flex">Returns </label>
            </div>
          </div>
        </div>
      </div>
      <!-- End Search -->
      <div class="bg-white shadow rounded py-2 my-3 relative">
        <!-- Legend -->
        <div class="absolute right-1 top-1">
          <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip">
            <font-awesome-icon class="text-lg" icon="info-circle" />
            <span class="tooltiptext shadow text-black mx-1 flex flex-col w-64 text-left right-0">
              <span class="text-green-500 mx-2">Paid</span>
              <span class="text-yellow-500 mx-2">Pending not paid</span>
              <span class="text-orange-400 mx-2">Canceled</span>
              <span class="text-red mx-2">Overdue</span>
              <span class="text-red mx-2">
                <font-awesome-icon icon="exclamation-triangle" class="mr-2" />Not in quickbooks
              </span>
            </span>
          </div>
        </div>
        <!-- End Legend -->

        <!-- Filter By -->
        <div class="relative py-4 px-8 flex justify-between text-sm gap-8">
          <div class="relative flex flex-col">
            <label for="salesman">Salesman</label>
            <div>
              <button
                class="absolute top-0 right-0 mr-2 hover:bg-red-200 px-2 -mb-px rounded"
                @click="clearSalesman"
              >
                <font-awesome-icon icon="times" />
              </button>
              <select
                id="salesman"
                class="styled shadow rounded"
                v-model="salesman"
                @change="clearOrders"
              >
                <option v-for="salesman in employees" :key="salesman._id" :value="salesman.name">
                  {{ salesman.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex flex-col w-72 relative">
            <label for="userInput">Company</label>
            <button
              class="absolute top-0 right-0 mr-2 hover:bg-red-200 px-2 -mb-px rounded"
              @click="setFilterByCompany({ name: null })"
            >
              <font-awesome-icon icon="times" />
            </button>
            <div v-if="filterByCompany">
              {{ filterByCompany }}
            </div>
            <div class="relative" v-else>
              <input
                autocomplete="new-password"
                type="text"
                name=""
                id="userInput"
                v-model="companySearch"
                @keydown.esc="(companySearch = null), ($event.target.value = null)"
                class="relative w-full styled shadow rounded"
              />

              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  class="absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-auto focus:outline-none sm:text-sm z-10 max-h-52"
                  v-if="showCompanies"
                >
                  <ul v-if="companies.length > 0">
                    <li
                      v-for="company in companies"
                      :key="company._id"
                      class="select-none relative py-2 flex items-center hover:bg-blue-200 cursor-pointer"
                      @click="setFilterByCompany(company)"
                    >
                      <div class="ml-3 block truncate text-sm">
                        <div>
                          {{ company.name }}
                        </div>
                        <div class="text-gray-500">
                          {{ company.accountNumber }}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="cursor-default select-none relative py-2 pl-3 pr-9">
                      No companies found
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>

          <div class="flex flex-col">
            <label for="startDate">order after</label>
            <input
              type="date"
              id="startDate"
              class="styled rounded shadow"
              v-model="startDate"
              @change="clearOrders"
            />
          </div>
          <div class="flex flex-col">
            <label for="endDate">order before</label>
            <input
              type="date"
              id="endDate"
              class="styled rounded shadow"
              v-model="endDate"
              @change="clearOrders"
            />
          </div>

          <div class="flex flex-col">
            <label for="startDeliveryDate">delivery after</label>
            <input
              type="date"
              id="startDeliveryDate"
              class="styled rounded shadow"
              v-model="startDeliveryDate"
              @change="clearOrders"
            />
          </div>
          <div class="flex flex-col">
            <label for="endDeliveryDate">deliver before</label>
            <input
              type="date"
              id="endDeliveryDate"
              class="styled rounded shadow"
              v-model="endDeliveryDate"
              @change="clearOrders"
            />
          </div>
        </div>
        <div class="grid grid-cols-9 py-2 w-full px-8">
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                class="styled border"
                type="checkbox"
                id="pending"
                v-model="pending"
                @change="clearOrders"
              />
            </div>
            <label for="pending"> Pending </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                class="styled border"
                type="checkbox"
                id="partial"
                v-model="partial"
                @change="clearOrders"
              />
            </div>
            <label for="partial"> Partially Fulfilled </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                class="styled border"
                type="checkbox"
                id="fulfilled"
                v-model="fulfilled"
                @change="clearOrders"
              />
            </div>
            <label for="fulfilled"> Fulfilled </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                class="styled border"
                type="checkbox"
                id="dropShipped"
                v-model="dropShipped"
                @change="clearOrders"
              />
            </div>
            <label for="dropShipped" class=""> Drop shipped </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                type="checkbox"
                class="styled border"
                id="showCanceled"
                v-model="showCanceled"
                @change="clearOrders"
              />
            </div>
            <label for="showCanceled"> Canceled </label>
          </div>
          <div class="relative" v-if="admin">
            <div class="absolute -left-1 w-4">
              <input
                type="checkbox"
                id="showSaved"
                class="styled border"
                v-model="showSaved"
                @change="clearOrders"
              />
            </div>
            <label for="showSaved"> Saved </label>
          </div>
          <div class="relative" v-if="admin">
            <div class="absolute -left-1 w-4">
              <input
                type="checkbox"
                id="showOverdue"
                class="styled border"
                v-model="showOverdue"
                @change="clearOrders"
              />
            </div>
            <label for="showOverdue"> Overdue </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                type="checkbox"
                id="showQuote"
                class="styled border"
                v-model="showQuote"
                @change="clearOrders"
              />
            </div>
            <label for="showQuote"> Quote </label>
          </div>
          <div class="relative">
            <div class="absolute -left-1 w-4">
              <input
                type="checkbox"
                id="showReturns"
                class="styled border"
                v-model="showReturns"
                @change="clearOrders"
              />
            </div>
            <label for="showReturns"> Returns </label>
          </div>
        </div>
        <!-- End Filter By -->
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 relative">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-if="fields.paymentType"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-if="fields.printed"
                    >
                      Print
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-if="fields.order_return"
                    >
                      Return
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('orderNumber')"
                    >
                      Number
                      <font-awesome-icon
                        icon="chevron-down"
                        id="orderNumber"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="md:col-span-2 self-center" @click="setSort('name')">
                        Customer
                        <font-awesome-icon
                          icon="chevron-down"
                          id="name"
                          class="chevron transition-transform duration-200"
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('status')"
                      v-if="fields.status"
                    >
                      Status
                      <font-awesome-icon
                        icon="chevron-down"
                        id="status"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('date')"
                      v-if="fields.date"
                    >
                      Date
                      <font-awesome-icon
                        icon="chevron-down"
                        id="date"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('deliveryDate')"
                      v-if="fields.deliveryDate"
                    >
                      Delivery Date
                      <font-awesome-icon
                        icon="chevron-down"
                        id="deliveryDate"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('shippingMethod')"
                      v-if="fields.shippingMethod"
                    >
                      Delivery Method
                      <font-awesome-icon
                        icon="chevron-down"
                        id="shippingMethod"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      @click="setSort('total')"
                      v-if="admin && fields.total"
                    >
                      Total
                      <font-awesome-icon
                        icon="chevron-down"
                        id="total"
                        class="chevron transition-transform duration-200"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-if="fields.purchaseOrder"
                    >
                      Purchase Order
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-if="fields.orderName"
                    >
                      Job Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <div v-if="loading" class="w-full text-center col-span-8 h-screen">
                  <Loading class="absolute" />
                </div>
                <tbody v-else class="bg-white divide-y divide-gray-200">
                  <tr v-for="order in orders" :key="order._id" class="relative">
                    <td class="px-6 py-4 whitespace-nowrap text-sm" v-if="fields.paymentType">
                      <router-link :to="'orders/' + order._id">
                        {{ order.paymentType }}
                      </router-link>
                    </td>
                    <td
                      v-if="fields.printed"
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        {{ order.carts.reduce((prev, current) => prev + current.print, 0) }}
                      </router-link>
                    </td>
                    <th
                      scope="col"
                      class="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500"
                      v-if="fields.order_return"
                    >
                      <span v-if="order.order_return">
                        <OrderReturnStatus :status="order.order_return.status" size="text-xs" />
                      </span>
                    </th>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      <span
                        class="absolute left-0 ml-1 text-red pulse"
                        v-if="
                          order.carts.some((el) => !el.quickbooksId && el.status === 'Fulfilled')
                        "
                      >
                        <font-awesome-icon icon="exclamation-triangle" />
                      </span>
                      <router-link :to="'orders/' + order._id">
                        {{ order.orderNumber }}
                      </router-link>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <router-link :to="'orders/' + order._id" class="flex flex-col">
                        <div class="text-sm font-medium text-gray-900 hover:text-action">
                          {{ order.name }}
                        </div>
                        <div class="text-xs text-gray-500" v-if="order.businessName">
                          ({{ order.businessName }})
                        </div>
                      </router-link>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <router-link
                        :to="'orders/' + order._id"
                        class="hover:text-action"
                        :class="getColor(order)"
                      >
                        {{ order.status }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="fields.date"
                    >
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        {{ order.date.slice(5, 10) }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="fields.deliveryDate"
                    >
                      <router-link
                        :to="'orders/' + order._id"
                        class="hover:text-action"
                        v-if="order.deliveryDate"
                      >
                        {{ order.deliveryDate.slice(5, 10) }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="fields.shippingMethod"
                    >
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        {{ getShippingWrapper(order.shippingMethod) }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="admin && fields.total"
                    >
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        ${{ order.total ? order.total.toFixed(2) : '0.00' }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="fields.purchaseOrder"
                    >
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        {{ order.purchaseOrder }}
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-if="fields.orderName"
                    >
                      <router-link
                        :to="'orders/' + order._id"
                        v-if="order.orderName != 'Cart'"
                        class="hover:text-action"
                      >
                        {{ order.orderName }}
                      </router-link>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <router-link :to="'orders/' + order._id" class="hover:text-action">
                        Edit
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        pageType="Order"
        :admin="admin"
        :totalAvailable="totalAvailable"
        :totalRevenue="total"
        :limit="limit"
        :selectedNumber="orders.length"
        :currentPage="currentPage"
        :selectableLimits="selectableLimits"
        @setCurrentPage="setCurrentPage"
        @setLimit="setLimit"
      />
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';
import { status as getColor } from '@/assets/functions/getColor.js';
import getShipping from '@/assets/functions/getShipping';
import Pagination from '@/components/shared/Pagination';
import OrderReturnStatus from '@/components/shared/OrderReturnStatus.vue';

export default {
  data: () => {
    return {
      selectedOrders: new Array(),
      showFields: false,
      fields: {
        date: true,
        deliveryDate: true,
        total: true,
        status: true,
        shippingMethod: true,
        paymentType: true,
        purchaseOrder: false,
        orderName: false,
        order_return: false,
      },
      query: '',
      queryFields: '',
      sort: '-date',
      salesman: null,

      startDate: null,
      endDate: null,
      endDeliveryDate: null,
      startDeliveryDate: null,
      showSaved: false,
      showCanceled: false,
      showQuote: false,
      showReturns: false,
      pending: true,
      partial: true,
      fulfilled: false,
      dropShipped: false,
      showOverdue: false,
      filterByCompany: null,
      ending: null,
      companySearch: null,

      //pagination
      currentPage: 1,
      limit: 50,
      selectableLimits: [25, 50, 100],
    };
  },
  computed: {
    orderSearch: {
      get() {
        return this.$store.getters['admin/getOrderSearch'];
      },
      set(val) {
        this.$store.commit('admin/setOrderSearch', val);
      },
    },
    search: {
      get() {
        return this.$store.getters['admin/getOrderCartSearch'];
      },
      set(val) {
        this.$store.commit('admin/setOrderCartSearch', val);
      },
    },
    orders() {
      return this.$store.getters['admin/getOrders'];
    },
    employees() {
      return this.$store.getters['users/getEmployees'];
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    totalAvailable() {
      return this.$store.getters['admin/getQueryTotal'];
    },
    total() {
      let total = 0;
      this.orders.forEach((el) => {
        total += el.total;
      });
      if (total) {
        total = total.toFixed(2);
      }
      return total;
    },
    loading: {
      get() {
        const val = this.$store.getters['admin/getLoading'];
        return val;
      },
    },
    companies() {
      const companies = this.$store.getters['company/getCompanies'];
      if (this.companySearch) {
        const search = this.companySearch;
        return companies.filter(
          (el) =>
            el.name.toLowerCase().includes(search) ||
            (el.accountNumber && el.accountNumber.toLowerCase().includes(search))
        );
      }
      return companies;
    },
    showCompanies() {
      return this.companySearch && this.companySearch.length > 0;
    },
  },

  watch: {
    pending() {
      window.localStorage.setItem('pending', this.pending);
    },
    fulfilled() {
      window.localStorage.setItem('fulfilled', this.fulfilled);
    },
    dropShipped() {
      window.localStorage.setItem('dropShipped', this.dropShipped);
    },
    showFields() {
      if (this.showFields === false) {
        this.clearOrders();
      }
    },
    loading() {
      this.scrollToPriorLocation();
    },
  },
  methods: {
    setLimit(payload) {
      this.currentPage = 1;
      this.limit = payload;
      this.clearOrders();
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
      this.$store.commit('setScroll');
      this.fetchData();
    },
    getShippingWrapper(method) {
      return getShipping(method);
    },
    setFilterByCompany(company) {
      this.filterByCompany = company.name;
      this.companySearch = null;
      this.clearOrders();
    },
    saveFields() {
      window.localStorage.setItem('fields', JSON.stringify(this.fields));
      window.localStorage.setItem('partial', this.partial);
      window.localStorage.setItem('pending', this.pending);
      window.localStorage.setItem('fulfilled', this.fulfilled);
      window.localStorage.setItem('dropShipped', this.dropShipped);
      window.localStorage.setItem('showSaved', this.showSaved);
      window.localStorage.setItem('canceled', this.showCanceled);
      window.localStorage.setItem('showOverdue', this.showOverdue);
      window.localStorage.setItem('showQuote', this.showQuote);
      window.localStorage.setItem('showReturns', this.showReturns);
    },
    getFields() {
      if (this.$route.query.fields) {
        const vals = this.$route.query.fields.split(',');
        const fieldsObject = {};

        vals.map((el) => {
          fieldsObject[el] = true;
        });
        window.localStorage.setItem('fields', JSON.stringify(fieldsObject));
      }

      if (this.$route.query.status) {
        if (this.$route.query.status.indexOf('Partially Fulfilled') > -1) {
          window.localStorage.setItem('partial', true);
        }

        if (this.$route.query.status.indexOf('Pending') > -1) {
          window.localStorage.setItem('pending', true);
        }

        if (this.$route.query.status.indexOf('Fulfilled') > -1) {
          window.localStorage.setItem('fulfilled', true);
        }

        if (this.$route.query.status.indexOf('Drop Ship') > -1) {
          window.localStorage.setItem('dropShipped', true);
        }

        if (this.$route.query.status.indexOf('Canceled') > -1) {
          window.localStorage.setItem('canceled', true);
        }
      }

      //this will go through and get the default objects to be viewed, but this will be overwritten
      //by what is retreived during the setUp() function.
      const fields = JSON.parse(window.localStorage.getItem('fields'));
      this.partial = JSON.parse(window.localStorage.getItem('partial'));
      this.pending = JSON.parse(window.localStorage.getItem('pending'));
      this.fulfilled = JSON.parse(window.localStorage.getItem('fulfilled'));
      this.dropShipped = JSON.parse(window.localStorage.getItem('dropShipped'));
      this.showSaved = JSON.parse(window.localStorage.getItem('showSaved'));
      this.showCanceled = JSON.parse(window.localStorage.getItem('canceled'));
      this.showQuote = JSON.parse(window.localStorage.getItem('showQuote'));
      this.showReturns = JSON.parse(window.localStorage.getItem('showReturns'));

      if (fields) {
        this.fields = fields;
      }
    },

    getColor: getColor,

    fetchData() {
      this.combineQuery();
      const query = `?${this.queryFields}${this.query}${this.ending}`;
      this.$store.dispatch('admin/getOrders', query);
    },

    clearOrders() {
      this.currentPage = 1;
      this.$store.commit('setScroll');
      this.saveFields();
      this.fetchData();
    },

    buildQueryFields() {
      this.queryFields =
        'fields=' +
        Object.keys(this.fields)
          .filter((key) => {
            return this.fields[key] ? encodeURIComponent(key) : null;
          })
          .join(',');
    },
    buildQuery() {
      this.query = '';
      if (this.pending) {
        this.query += '&status=Pending';
      }

      if (this.partial) {
        this.query += '&status=' + encodeURIComponent('Partially Fulfilled');
      }

      if (this.fulfilled) {
        this.query += '&status=Fulfilled';
      }

      if (this.showCanceled) {
        this.query += '&status=Canceled';
      }

      if (this.dropShipped) {
        this.query += '&status=' + encodeURIComponent('Drop Ship');
      }

      if (this.showSaved) {
        this.query += '&status=Saved';
      }
      if (this.showOverdue) {
        this.query += '&overdue=true';
      }
      if (this.showQuote) {
        this.query += '&status=Quote';
      }

      if (this.showReturns) {
        this.query += '&order_return[exists]=true';
      }

      if (this.filterByCompany) {
        this.query += `&businessName=${encodeURIComponent(this.filterByCompany)}`;
      }

      if (this.startDate) {
        const d = new Date(this.startDate);
        this.query +=
          '&date[gte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.endDate) {
        const d = new Date(this.endDate);
        this.query +=
          '&date[lte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.startDeliveryDate) {
        const d = new Date(this.startDeliveryDate);
        this.query +=
          '&deliveryDate[gte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.endDeliveryDate) {
        const d = new Date(this.endDeliveryDate);
        this.query +=
          '&deliveryDate[lte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.salesman) {
        this.query += `&salesman=${this.salesman}`;
      }
      if (this.orderSearch) {
        this.query += `&search=${this.orderSearch}`;
      }

      this.query += '&page=' + this.currentPage;
    },
    combineQuery() {
      this.buildQueryFields();
      this.buildQuery();
      this.ending = `&sort=${this.sort}&limit=${this.limit}`;
      this.$router
        .push({ path: `/admin/orders?${this.queryFields}${this.query}${this.ending}` })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error);
          }
        });
    },
    setSort(selection) {
      //return ;
      const chevrons = document.getElementsByClassName('chevron');
      chevrons.forEach((el) => {
        if (el.classList.contains('flip')) {
          el.classList.remove('flip');
        }
      });
      const selected = document.getElementById(selection);
      if (selection !== this.sort) {
        selected.classList.add('flip');
      }

      if (this.sort === selection) {
        this.sort = `-${selection}`;
      } else {
        this.sort = selection;
      }
      this.clearOrders();
    },
    getBackGroundColor(order) {
      if (order.status === 'Fulfilled')
        if (order.paid) {
          return 'bg-green-100';
        } else if (order.overdue) {
          return 'bg-red-200';
        } else {
          return 'bg-gray-100';
        }
      if (order.status === 'Pending') return 'bg-yellow-100';
      if (order.status === 'Canceled') return 'bg-orange-100';
    },
    clearSalesman() {
      this.salesman = null;
      this.clearOrders();
    },
    setPage() {
      this.getFields();

      const query = this.$route.query;

      this.limit = query.limit ? query.limit : 50;
      this.currentPage = query.page ? query.page : 1;
      this.sort = query.sort ? query.sort : '-date';
      this.salesman = query.salesman ? query.salesman : null;
      this.filterByCompany = query.businessName ? query.businessName : null;

      if (query['date[gte]']) {
        this.startDate = new Date(query['date[gte]']).toISOString().split('T')[0].slice(0, 10);
      }

      if (query['date[lte]']) {
        this.endDate = new Date(query['date[lte]']).toISOString().split('T')[0].slice(0, 10);
      }

      if (query['deliveryDate[gte]']) {
        this.startDeliveryDate = new Date(query['deliveryDate[gte]'])
          .toISOString()
          .split('T')[0]
          .slice(0, 10);
      }

      if (query['deliveryDate[lte]']) {
        this.endDeliveryDate = new Date(query['deliveryDate[lte]'])
          .toISOString()
          .split('T')[0]
          .slice(0, 10);
      }

      if (this.$route.query.status) {
        this.$route.query.status.forEach((el) => {
          this[el] = true;
        });
      }

      this.fetchData();
      this.$store.dispatch('company/getCompanies', 'sort=name');
    },
    scrollToPriorLocation() {
      if (!this.loading) {
        const scroll = this.$route.query.scrollTo
          ? this.$route.query.scrollTo
          : this.$store.getters['getScroll'](this.$route.name);
        if (scroll) {
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: scroll,
              behavior: 'smooth',
            });
          }, 500);
        }
      }
    },
  },
  components: {
    Loading,
    OrderReturnStatus,
    Pagination,
  },
  beforeRouteLeave(to, from, next) {
    this.saveFields();
    this.$store.commit('setScroll', { name: this.$route.name, position: parseInt(window.scrollY) });
    next();
  },
  mounted() {
    this.setPage();
  },
};
</script>
<style scoped>
.flip {
  transform: rotate(180deg);
}

label {
  padding-left: 0.2rem;
}
</style>
