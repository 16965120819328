<!--
This component can have protected stuff inside of it because it is in the edit orders section and the users order section. 
-->
<template>
  <div>
    <!-- Start of the product grid -->
    <div
      class="flex border-b grid-cols-10 font-bold text-sm p-2 bg-primary-alt justify-between items-center relative"
    >
      <div
        class="absolute -left-1 -top-1 px-1 text-red pulse text-xl rounded-full bg-black"
        v-if="cart.status === 'Fulfilled' && !cart.quickbooksId"
      >
        <font-awesome-icon icon="exclamation-triangle" />
      </div>
      <button
        class="py-1 px-2 hover:bg-primary hidden md:block hover:text-white rounded"
        @click="$emit('printInvoice')"
      >
        <font-awesome-icon icon="print" /> Invoice
      </button>
      <div class="col-span-5">#{{ cart.name ? cart.name : order.orderNumber }}</div>

      <div class="hidden md:flex">
        <div class="">Printed</div>
        <div class="mx-1">
          {{ cart.print }}
        </div>
      </div>

      <div class="hidden md:flex" v-if="cart.status === 'Fulfilled'">
        <div class="w-6">QB</div>
        <div v-if="user.email === 'accounting@pagecustomsupplyllc.com'">
          <input type="number" v-model="cart.quickbooksId" />
        </div>
        <div class="text-primary-dark font-semibold">
          {{ cart.quickbooksId }}
        </div>
      </div>
      <div>
        {{ cart.status }}
      </div>
      <button
        v-if="cart.cartItems.length > 0"
        class="py-1 px-2 hover:bg-primary hover:text-white rounded"
        @click="$emit('printTicket')"
      >
        Ticket
        <font-awesome-icon icon="print" />
      </button>
      <button
        v-else
        class="py-1 px-2 hover:bg-primary hover:text-white rounded"
        @click="$emit('deleteSection')"
      >
        Remove
        <font-awesome-icon icon="times" />
      </button>
    </div>
    <div
      v-if="cart.fulfilled && cart.fulfilled.length > 0"
      class="flex justify-between text-xs px-4 overflow-x-auto overflow-hidden flex-no-wrap scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
    >
      <div class="whitespace-no-wrap">Fulfilled by:</div>
      <div v-for="val in cart.fulfilled" :key="val" class="whitespace-no-wrap mx-2">{{ val }}</div>
    </div>
    <div class="overflow-hidden border-b border-gray-200">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              price
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              Stock
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              picked/quantity
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell tracking-wider"
            >
              Subtotal
            </th>
            <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">remove</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, itemIndx) in lineItems">
            <tr :class="itemIndx % 2 === 0 ? 'bg-white' : 'bg-gray-50'" :key="item._id">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div class="flex justify-items-start">
                  <button
                    :id="item._id"
                    @click="addToSplitArray(item)"
                    class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden self-center mr-4"
                  >
                    <img v-lazy="item.image" />
                  </button>
                  <button @click="handleNavigation(item.productId)" class="text-left">
                    <div>
                      {{ item.name }}
                    </div>
                    <div class="text-sm text-gray-600">{{ item.productNum }}</div>
                    <div class="text-sm text-red md:hidden">
                      <span v-if="item.fulfilled">{{ item.quantity }}</span>
                      <span v-else>_</span> / {{ item.quantity }}
                    </div>
                  </button>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm hidden md:table-cell">
                <div class="relative" v-if="!locked && !cart.paid">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">$</span>
                  </div>

                  <input
                    type="number"
                    class="font-semibold w-20 border border-gray-300 rounded-md pl-6"
                    v-model="item.price"
                    @change="alter"
                    @blur="logPriceChange(item)"
                    step="any"
                  />
                </div>
                <span v-else class="font-semibold w-20 text-center rounded-md">
                  ${{ item.price }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                <div class="flex justify-between">
                  <div class="relative tooltip text-green-300">
                    <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                      At Purchase
                    </div>

                    {{ item.stock ? item.stock : 0 }}
                  </div>
                  <div class="relative tooltip text-primary-dark">
                    <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                      Live Inventory
                    </div>
                    {{ item.liveStock }}
                  </div>
                </div>
              </td>

              <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                <div class="items-center flex flex-col justify-start overflow-hidden">
                  <div class="border rounded-md">
                    <input
                      type="number"
                      v-model="item.fulfilled"
                      class="w-16 text-right fulfilled-input rounded-l-md p-1"
                      :tabindex="index + 1"
                      @change="
                        updateBundleItemFulfilledQuantity(item);
                        alter();
                      "
                      :disabled="locked"
                    />
                    /
                    <input
                      type="number"
                      v-model="item.quantity"
                      class="w-16 text-left fulfilled-input rounded-r-md p-1"
                      :disabled="locked || cart.paid"
                      @change="alter"
                      :tabindex="index + 2"
                    />
                  </div>
                </div>
                <div class="text-xs text-center">
                  {{ item.pieceSize ? `(${item.pieceSize})` : '' }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                $ {{ (item.price * item.quantity).toFixed(2) }}
              </td>
              <td
                class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium hidden md:table-cell"
              >
                <button
                  v-if="!locked && !cart.paid"
                  class="sm:top-1 mt-2 sm:mt-1 hover:bg-red-200 cursor-pointer rounded py-1 px-2"
                  @click="removeFromCart(item)"
                  type="button"
                >
                  <font-awesome-icon icon="times" />
                </button>
              </td>
            </tr>
            <template v-if="item.isBundle">
              <tr
                v-for="bundleItem in item.bundleItems"
                :class="itemIndx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                :key="bundleItem._id"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <div class="flex justify-items-start">
                    <button
                      :id="bundleItem._id"
                      class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden self-center mr-4 ml-5"
                    >
                      <img v-lazy="bundleItem.variant.image" />
                    </button>
                    <button @click="handleNavigation(bundleItem.product)" class="text-left">
                      <div>
                        {{ bundleItem.productTitle }}
                      </div>
                      <div class="text-sm text-gray-600">{{ bundleItem.productNum }}</div>
                      <div class="text-sm text-red md:hidden">
                        <span v-if="bundleItem.fulfilled">{{ bundleItem.quantity }}</span>
                        <span v-else>_</span> / {{ bundleItem.quantity }}
                      </div>
                    </button>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                  <div class="relative" v-if="!locked">
                    <span class="absolute top-0 my-1 font-large">$</span>

                    <input
                      type="number"
                      class="font-semibold w-20 text-center border rounded-md"
                      v-model="bundleItem.price"
                      @change="alter"
                      step="any"
                      disabled
                    />
                  </div>
                  <span v-else class="font-semibold w-20 text-center border rounded-md">
                    ${{ bundleItem.price }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                  <div class="flex justify-between">
                    <div class="relative tooltip text-green-300">
                      <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                        At Purchase
                      </div>

                      {{ bundleItem.variant.stock ? bundleItem.variant.stock : 0 }}
                    </div>
                    <div class="relative tooltip text-primary-dark">
                      <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">
                        Live Inventory
                      </div>
                      {{ bundleItem.variant.liveStock }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                  <div class="flex items-center justify-start mr-4 col-span-2 overflow-hidden">
                    <input
                      type="number"
                      v-model="bundleItem.fulfilled"
                      class="w-12 text-right fulfilled-input border rounded-md ml-2"
                      :tabindex="index + 1"
                      @change="
                        updateBundleFulfilledQuantity(item);
                        alter();
                      "
                      :disabled="locked || cart.paid"
                    />
                    /
                    <input
                      type="number"
                      :value="bundleItem.quantity * item.quantity"
                      class="w-12 text-left fulfilled-input border rounded-md"
                      disabled
                      @change="alter()"
                      :tabindex="index + 2"
                    />
                    <span class="text-xs">
                      {{ bundleItem.pieceSize ? `(${bundleItem.pieceSize})` : '' }}
                    </span>
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium hidden md:table-cell"
                ></td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <!-- End of product table -->
    <div class="flex justify-between">
      <div
        class="p-2 hidden md:flex space-y-2 justify-between w-full relative"
        :class="displayTotals ? 'flex-col' : ''"
      >
        <div class="flex items-center">
          <label for="trackingNumber" class="whitespace-no-wrap">Tracking Number:</label>
          <input
            type="text"
            v-model="cart.trackingNumber"
            class="rounded-md border border-gray-300 ml-1"
          />
        </div>
        <div class="flex" v-if="admin">
          <div class="w-32">Paid</div>
          <div class="mx-1">
            <ToggleButton
              color="#005785"
              :width="60"
              :value="cart.paid"
              :labels="{ checked: 'yes', unchecked: 'no' }"
              :margin="3"
              :disabled="locked"
              @change="cart.paid = !cart.paid"
            />
          </div>
        </div>
        <div>
          <DualButton
            :options="dualButtonOptions"
            verticalPosition="top"
            @execute="handleButton($event)"
            position="right"
          />
        </div>
      </div>
      <div class="md:w-1/2" v-if="displayTotals">
        <OrderTotals
          :products="cart.cartItems"
          :taxRate="order.taxRate"
          :discount="0"
          :shipping="index === 0 ? order.shipping : 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderTotals from '@/components/shared/NewOrderTotals.vue';
import DualButton from '@/components/shared/DualButton';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  props: {
    cart: { type: Object },
    order: { type: Object, required: true },
    index: Number,
    admin: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    aleteredOrder: { type: Boolean },
    displayTotals: { type: Boolean, default: true },
  },
  data: function () {
    return {
      toggleWidth: 60,
      splitArray: [],
      altered: false,
    };
  },
  computed: {
    lineItems() {
      return this.cart.cartItems;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    dualButtonOptions() {
      const options = [
        {
          name: 'Add product',
          buttonMethod: 'add_product',
        },
      ];

      if (this.cart.quickbooksId && this.cart.status === 'Fulfilled') {
        options.push({
          name: 'Update invoice ',
          buttonMethod: 'update_invoice',
          text: 'Update invoice in quickbooks',
        });
      } else if (this.cart.status === 'Awaiting Products') {
        options.push({
          name: 'Create purchase order',
          buttonMethod: 'create_purchase_order',
          text: 'Sync order to quickbooks to create a purchase order.',
        });
      } else {
        options.push({
          name: 'Create invoice',
          buttonMethod: 'create_invoice',
          text: 'Create an invoice in quickbooks',
        });
      }
      if (this.cart.status != 'Fulfilled' && !this.locked) {
        options.push({
          name: 'Split order',
          buttonMethod: 'split_order',
          text:
            'Select the products you would like to seperate into a seperate cart by clicking on their picture prior to clicking this button.',
        });
      }
      return options;
    },
  },
  watch: {
    aleteredOrder() {
      if (this.aleteredOrder) {
        this.altered = true;
      }
    },
  },
  methods: {
    handleButton(option) {
      switch (option.buttonMethod) {
        case 'add_product':
          this.handleAddProduct();
          return;
        case 'split_order':
          this.prepareSplit();
          return;
        default:
          this.quickbooks();
      }
    },

    updateBundleFulfilledQuantity(lineItem) {
      let itemFulfilled = null;
      for (let index = 0; index < lineItem.bundleItems.length; index++) {
        const element = lineItem.bundleItems[index];
        const fulfilledBundle = Math.floor(+element.fulfilled / +element.quantity);
        if (!itemFulfilled || itemFulfilled > fulfilledBundle) {
          itemFulfilled = fulfilledBundle;
        }
      }
      lineItem.fulfilled = itemFulfilled;
    },
    updateBundleItemFulfilledQuantity(lineItem) {
      if (lineItem.isBundle) {
        for (let index = 0; index < lineItem.bundleItems.length; index++) {
          const bundleItem = lineItem.bundleItems[index];
          bundleItem.fulfilled = +bundleItem.quantity * +lineItem.fulfilled;
        }
      }
    },
    handleAddProduct() {
      if (!this.cart.paid) {
        this.$emit('showAddItem', this.index);
        this.alter();
      } else {
        this.$store.commit('setError', {
          message: `Cannot add product to order that has been paid.`,
          color: 'red',
          duration: 10000,
        });
      }
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', { cartItem: item, index: this.index });
      this.alter();
    },
    removeScrolling() {
      const inputs = document.getElementsByClassName('fulfilled-input');
      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].addEventListener('mousewheel', function () {
            this.blur();
          });
        }
      }
    },
    alter() {
      this.altered = true;
      this.updateStatus();
      this.$store.commit('order/alterKey');
    },
    logPriceChange(item) {
      this.$store.dispatch('createLog', {
        userEmail: this.user.email,
        type: 'Order',
        category: 'Update',
        name: `PCS-${this.order.orderNumber}`,
        description: `Changed the price of ${item.name} to $${item.price} `,
      });
    },
    prepareSplit() {
      if (this.splitArray.length === 0) {
        this.$store.commit('setError', {
          message:
            'Products have to be selected to split an order, select a product by clicking on the product image',
          color: 'red',
          duration: 0,
        });
      } else {
        this.splitArray.forEach((el) => {
          if (el.fulfilled > 0) {
            const itemShipping = el.itemShipping / el.quantity;
            const itemDiscount = el.itemDiscount / el.quantity;
            el.itemDiscount = itemDiscount * (el.quantity - el.fulfilled);
            el.itemShipping = itemShipping * (el.quantity - el.fulfilled);
          }
          el.quantity = el.quantity - el.fulfilled;
          el.fulfilled = 0;
          const d = document.getElementById(el._id);
          d.classList.remove('selected');
        });
        this.$emit('splitOrder', {
          cartItems: this.splitArray,
          quickbooksId: this.cart.quickbooksId,
        });
        this.cleanCart();
      }
    },
    cleanCart() {
      for (let i = 0; i < this.splitArray.length; i++) {
        for (let j = 0; j < this.cart.cartItems.length; j++) {
          if (this.splitArray[i]._id === this.cart.cartItems[j]._id) {
            if (this.cart.cartItems[j].fulfilled == 0) {
              this.cart.cartItems.splice(j, 1);
            } else {
              const el = this.cart.cartItems[j];
              if (el.fulfilled > 0) {
                const itemShipping = el.itemShipping / el.quantity;
                const itemDiscount = el.itemDiscount / el.quantity;
                el.itemDiscount = itemDiscount * el.fulfilled;
                el.itemShipping = itemShipping * el.fulfilled;
              }
              el.quantity = el.fulfilled;
            }
          }
        }
      }
      this.splitArray = [];
    },
    addToSplitArray(item) {
      const itemPicture = document.getElementById(item._id);
      if (itemPicture.classList.contains('selected')) {
        itemPicture.classList.remove('selected');
        this.splitArray = this.splitArray.filter((el) => {
          return el._id !== item._id;
        });
      } else if (item.fulfilled < item.quantity) {
        itemPicture.classList.add('selected');
        this.splitArray.push({ ...item });
      }
    },
    addProductsToReturn() {
      if (this.splitArray.length == 0) {
        this.$store.commit('setError', {
          message: 'You cannot start a return without any products selected.',
          color: 'red',
        });
        return;
      }
      this.$emit('addProductsToReturn', this.splitArray);
    },
    updateStatus() {
      let flag = 'Fulfilled';
      for (let i = 0; i < this.cart.cartItems.length; i++) {
        if (this.cart.cartItems[i].fulfilled * 1 < this.cart.cartItems[i].quantity * 1) {
          flag = 'Awaiting Products';
        }
      }
      this.cart.status = flag;
    },
    handleNavigation(id) {
      const val = `/admin/products/${id}`;
      const routeData = this.$router.resolve({
        path: val,
      });
      window.open(routeData.href, '_blank');
    },
    quickbooks() {
      if (this.cart.status === 'Awaiting Products') {
        this.$store.dispatch('quickbooks/createPurchaseOrder', {
          _id: this.order._id,
          index: this.index,
        });
      } else {
        this.$store.dispatch('quickbooks/createInvoice', {
          _id: this.order._id,
          index: this.index,
        });
      }
      this.$emit('refresh');
    },
  },
  components: {
    OrderTotals,
    DualButton,
    ToggleButton,
  },
  mounted() {
    this.removeScrolling();
    this.updateStatus();
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.selected {
  border: #00a0b1 solid 1px !important;
  border-radius: 1rem;
}
</style>
