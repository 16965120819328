<template>
  <div class="">
    <div class="grid grid-cols-5">
      <div>{{ entry.type }}</div>
      <div>{{ entry.category }}</div>
      <div>{{ getPrettyLocalTime(entry.date) }}</div>
      <div class="overflow-hidden">{{ entry.userEmail }}</div>
      <div class="text-right">{{ entry.name }}</div>
      <div class="col-span-5">{{ entry.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    entry: {
      type: Object,
    },
  },
  computed: {},
  watch: {},
  methods: {
    getPrettyLocalTime(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
