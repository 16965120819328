var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.status === 0
      ? `text-gray-800 ${_vm.size}`
      : _vm.status === 1
      ? `text-blue-500 ${_vm.size}`
      : _vm.status >= 5
      ? `text-gray-500 ${_vm.size}`
      : `text-green-500 ${_vm.size}`},[_vm._v(" "+_vm._s(_vm.returnStatus)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }