<template>
  <div class="text-center">
    <h4>Credit Card information:</h4>
    <card class="stripe-card my-2 mx-auto" :stripe="stripeKey" />
    <div v-if="error" class="text-red">{{ error }}</div>
    <div class="flex mx-auto w-64">
      <img src="/images/static/1.png" class="h-10 mx-2" alt="" />
      <img src="/images/static/2.png" class="h-10 mx-2" alt="" />
      <img src="/images/static/3.png" class="h-10 mx-2" alt="" />
    </div>
    <div class="flex items-center justify-center">
      <div>
        <button
          type="button"
          class="pay-with-stripe btn-primary w-full sm:w-1/3 md:w-auto rounded-l-md my-4 mx-auto border border-primary"
          @click="pay"
        >
          Charge card
        </button>
      </div>
      <StripeHandheld :order="order" @progress="progress" />
    </div>
  </div>
</template>

<script>
import StripeHandheld from '@/components/admin/shared/StripeHandheld.vue';
import { Card, createToken } from 'vue-stripe-elements-plus';
import keys from '@/keys.json';
// import Loading from '@/components/shared/Loading';
export default {
  props: {
    order: Object,
  },
  data: function () {
    return {
      stripeOptions: {},
      stripeKey: keys.STRIPE_KEY,
      creditCardCollected: false,
      error: null,
    };
  },
  computed: {
    stripeToken: {
      get() {
        return this.$store.state.order.stripeToken;
      },
      set(val) {
        this.$store.commit('editOrder/setToken', val);
      },
    },
  },
  methods: {
    async pay() {
      this.stripeOptions.name = this.order.name;
      this.stripeOptions.address_line1 = this.order.billingAddress;
      this.stripeOptions.address_city = this.order.billingCity;
      this.stripeOptions.address_state = this.order.billingState;
      this.stripeOptions.address_zip = this.order.billingZip;

      const response = await createToken(this.stripeOptions);
      if (response.token) {
        this.$store.dispatch('editOrder/chargeCard', {
          order: this.order,
          stripeToken: response.token,
        });
        this.$emit('finished');
      } else {
        this.error = 'Credit Card validation has failed, please use a different card.';
      }
    },
    progress() {
      this.order.paid = true;
      this.$store.dispatch('editOrder/updateOrder', this.order);
      this.$store.dispatch('createLog', {
        userEmail: this.order.email,
        type: 'Order',
        category: 'Update',
        name: `PCS-${this.order.orderNumber}`,
        description: `Made payment of ${this.order.total} using handheld from the Edit Order Page`,
      });
      this.$emit('finished');
    },
  },
  components: {
    Card,
    StripeHandheld,
  },
};
</script>

<style scoped>
.stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
label {
  margin-bottom: 0.25rem;
}
label {
  margin-bottom: 0.25rem;
}
input[type='radio'] {
  margin: 0.5rem;
}
</style>
