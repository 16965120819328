exports.print = (orders) => {
  let pickSummary = '';
  let receiptSummary = '';
  let waterMarkedSummary = '';
  let body = '';

  orders.forEach((order) => {
    waterMarkedSummary = waterMarkedSlip(order);
    pickSummary = packingSlip(order);
    receiptSummary = companyReceipt(order);
    body += waterMarkedSummary;
    body += pickSummary;
    body += receiptSummary;
  });

  print(body);
};

exports.invoice = (orders) => {
  let pickSummary = '';
  let receiptSummary = '';
  let officeCopy = '';
  let body = '';
  orders.forEach((order) => {
    officeCopy = waterMarkedSlip(order);
    pickSummary = packingSlip(order);
    receiptSummary = invoice(order);
    body += officeCopy;
    body += pickSummary;
    body += receiptSummary;
  });

  print(body);
};

exports.printWaterMark = (orders) => {
  let officeCopy = '';
  let body = '';
  orders.forEach((order) => {
    officeCopy = waterMarkedSlip(order);
    body += officeCopy;
  });

  print(body);
};

exports.printZebra = (orders) => {
  let body = '';
  orders.forEach((order) => {
    body += zebraPrint(order);
  });
  print(body);
};

const print = (body) => {
  const a = window.open('', '', 'height:500, width=500');

  a.document.write('<html>');
  a.document.write('<body style="padding: 10px 25px; position: relative;">');
  a.document.write(body);
  a.document.write('</body>');
  a.document.write(
    '<style>.flex{display:flex;}.grid{display:grid; grid-gap: 1rem;gap: 1rem;}.grid-cols-3{grid-template-columns: repeat(3, minmax(0, 1fr));}.grid-cols-12{grid-template-columns: repeat(12, minmax(0, 1fr));}.col-span-3{grid-column: span 3 / span 3;}.col-span-2{grid-column: span 2 / span 2;}.w-full{width:100%;}.grid-cols-2{grid-template-columns: repeat(2,minmax(0,1fr));}ml-auto{margin-left:auto;} .w-32{width:8rem;} .text-primary-dark{color: #4d89aa;}</style>'
  );
  a.document.write('</html>');
  a.document.close();
  setTimeout(() => {
    a.print();
  }, 250);
};

const zebraPrint = (order) => {
  let summary = `<div style="text-align: center;"><h2>${
    order.businessName ? order.businessName : order.name
  }<br/></h2>`;
  summary += `<div style="font-size: 48px; font-weight: bolder;">#${order.orderNumber}</div><hr/>`;
  if (order.orderName)
    summary += `<div style="font-size: 96px; font-weight: bold;">${order.orderName}</div> <br/>`;

  if (order.purchaseOrder)
    summary += `<div style="font-size: 32px; font-weight: bold;">PO: ${order.purchaseOrder}</div>`;

  summary += '</div>';
  return summary;
};

const waterMarkedSlip = (order) => {
  let totalItems = 0;
  let pickSummary = ``;
  for (let i = 0; i < order.cart.cartItems.length; i++) {
    const item = order.cart.cartItems[i];
    pickSummary += `<div style="display: flex;  width: 100%; margin: 10px 0; font-size: 12px;">

      <div style="width: 30%;">${item.name}</div>
      <div style="width: 15%;">${item.productNum}</div>
      <div style="width: 15%;">${item.binLocation}</div>
      <div style="width: 10%;">${item.stock ? item.stock : 0}</div>

      <div style="width: 15%; ${item.quantity <= item.fulfilled ? 'color: lightgray;' : null}">${
      item.quantity <= item.fulfilled ? '0' : '__'
    } / ${item.quantity - item.fulfilled} ${item.pieceSize ? `(${item.pieceSize})` : ''}</div>

      <div class="margin-right: 5px;">____ &nbsp&nbsp</div>
      <div>____</div>
      </div>`;
    if (item.isBundle) {
      totalItems += 1;
      for (let j = 0; j < item.bundleItems.length; j += 1) {
        const bundleItem = item.bundleItems[j];
        totalItems += 1;
        pickSummary += `<div style="display: flex;  width: 100%; margin: 10px 0; font-size: 12px;">

        <div style="width: calc(30% - 10px);padding-left: 10px;">${bundleItem.productTitle}</div>
        <div style="width: 15%;">${bundleItem.productNum}</div>
        <div style="width: 15%;">${bundleItem.variant.binLocation}</div>
        <div style="width: 10%;">${bundleItem.variant.stock ? bundleItem.variant.stock : 0}</div>

        <div style="width: 15%; ${
          bundleItem.quantity * item.quantity <= bundleItem.fulfilled ? 'color: lightgray;' : null
        }">${bundleItem.quantity * item.quantity <= bundleItem.fulfilled ? '0' : '__'} / ${
          bundleItem.quantity * item.quantity - bundleItem.fulfilled
        } ${bundleItem.variant.pieceSize ? `(${bundleItem.variant.pieceSize})` : ''}</div>

        <div class="margin-right: 5px;">____ &nbsp&nbsp</div>
        <div>____</div>
        </div>`;
      }
    } else {
      totalItems += 1;
    }
  }

  const waterMark = order.status == 'Canceled' ? 'CANCELED' : 'OFFICE COPY';
  const height = totalItems < 25 ? '98vh' : totalItems < 70 ? '195vh' : '300vh';
  let body = '';
  body += `<div style="min-height: ${height};  font-size: 12px; position:relative;">
  <div style="position: absolute; bottom: 25%; right: 25%; transform:rotate(45deg); font-size: 72px; opacity: 0.25; ${
    order.status === 'Canceled' ? `color: red` : null
  }">
    ${waterMark}<br/>
    ${waterMark}<br/>
    ${waterMark}<br/>
    ${waterMark}
  </div>
  <h3 style="text-align: center;">
  Order: #${order.cart.name ? order.cart.name : order.orderNumber}, ${
    order.businessName ? order.businessName : order.name
  }. ${order.date.substring(0, 10)}
  </h3>
  <br/><br/>
  <div style="display:flex;">
    <div style="width: 32%;">
      <div style="color:#005785; font-weight:bold;">Name</div>
      <div>${order.name}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Phone</div>
      <div>${order.phone}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Deliver by</div>
      <div>${order.deliveryDate ? order.deliveryDate.substring(0, 10) : null}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Purchase Order</div>
      <div>${order.purchaseOrder ? order.purchaseOrder : 'None'}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Delivery Method</div>
      <div>${order.shippingMethod == 'call' ? 'Will Call' : order.shippingMethod}</div>
  `;
  if (order.orderName != 'Cart') {
    body += `
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Job Name</div>
        <div>${order.orderName}</div>
      `;
  }

  body += `
    </div>
    <div style="width: 32%; ">
      <div style="color:#005785; font-weight:bold;">Customer Note:</div>
      ${order.customerNote ? order.customerNote : ''}
      <div style="color:#005785; font-weight:bold;">Delivery Note:</div>
      ${order.deliveryNote ? order.deliveryNote : ''}
    </div>
    <div style="width: 32%;">
      <div style="color:#005785; font-weight:bold;">Shipping Address</div>
      <div>${order.shippingAddress}</div>
      <div>${order.shippingCity}</div>
      <div style="display:flex;">
        <div style="width: 50%;">${order.shippingState}</div>
        <div>${order.shippingZip}</div>
      </div>
    </div>
  </div>
  <hr/>
  <div style="display: flex; width: 100%; margin: 20px 0;">
    <div style="width: 30%;">Name</div>
    <div style="width: 15%;">Product Num</div>
    <div style="width: 15%;">Bin Loc</div>
    <div style="width: 10%;">Stock</div>
    <div style="width: 15%;">Qty</div>
    <div style="width: 10%;">Signatures</div>
    <div></div>
  </div>
  <div style="padding: 0 0 25px 0;">${pickSummary}</div>
  <hr/>
  <hr/>
  <div style="position: absolute; right:0; bottom:0;">
  <img src="${order.qrCode}"/>
  </div>
  </div>
  `;
  return body;
};

const invoice = (order) => {
  let summary = '';
  const totals = {
    subTotal: 0,
    tax: 0,
    shipping: order.shipping,
    total: 0,
  };

  for (let i = 0; i < order.cart.cartItems.length; i++) {
    const item = order.cart.cartItems[i];
    totals.subTotal += order.cart.cartItems[i].quantity * order.cart.cartItems[i].price;
    summary += `<div style="display: flex; width: 100%;">
    <div style="width: 20%;">
    <div style="margin: 0 auto; overflow:hidden; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06); border-radius: 0.5rem; height: 3rem; width: 3rem;">
      ${
        item.image
          ? `
      <img  style="width: 100%" src="https://pagecustomsupplyllc.com${item.image}" alt="image of ${item.name}"/>
      `
          : ''
      }
    </div>
    </div>
    <div style="width: 40%;">${item.name}</div>
    <div style="width: 20%;">${item.productNum}</div>
    `;

    if (order.type != 'Commercial') {
      summary += `
    <div style="width: 10%;">$${item.price ? item.price.toFixed(2) : item.price}</div>
      
      `;
    }

    summary += `
    <div style="width: 10%;">${item.quantity}</div>
    </div>`;
  }

  if (order.tax) {
    totals.tax = totals.subTotal * 0.0775;
  }

  if (order.shipping) {
    totals.shipping = order.shipping;
  }

  totals.total = totals.subTotal + totals.tax + totals.shipping;

  totals.total = totals.total.toFixed(2);
  totals.subTotal = totals.subTotal.toFixed(2);
  totals.tax = totals.tax.toFixed(2);
  totals.shipping = totals.shipping.toFixed(2);

  let body = `<h3 style="text-align: center;">Thank you for your recent Purchase!</h3>
  <br/><br/>
  <div><strong>Order: #${order.orderNumber}</strong></div>
  ${order.purchaseOrder ? `<div><strong>PO: ${order.purchaseOrder}</strong></div>` : ``}
  ${order.orderName != 'Cart' ? `<div><strong>Job Name: ${order.orderName}</strong></div>` : ``}

  <br/>
  <div style="display:flex;">
    <div style="width: 50%;">
      <div style="color:#005785; font-weight:bold;">Name</div>
      <div>${order.name}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Phone</div>
      <div>${order.phone}</div>
    </div>
    <div style="">
      <div style="color:#005785; font-weight:bold;">Address</div>
      <div>${order.shippingAddress}</div>
      <div>${order.shippingCity}</div>
      <div style="display:flex;">
        <div style="width: 50%;">${order.shippingState}</div>
        <div>${order.shippingZip}</div>
      </div>
    </div>
  </div>
  <hr/>
  <div style="padding: 25px 0 25px;">${summary}</div>
  <hr/>
  <div style="display:flex; width: 100%;">
    <div style="width: 50%;">
      <div>
      <div style="color:#005785;">Billing Address</div>
      <div>${order.billingAddress ? order.billingAddress : ''}</div>
      <div>${order.billingCity ? order.billingCity : ''}</div>
      <div style="display:flex;">
        <div style="width: 25%;">${order.billingState ? order.billingState : ''}</div>
        <div>${order.billingZip ? order.billingZip : ''}</div>
      </div>
      </div>
      <div></div>
    </div>
    <div style="text-align: right; width: 49%;">
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));	 width: 100%; align-content: space-between;	">
        <div style="width: 100px;">Subtotal:</div>
        <div style="">$
        ${totals.subTotal}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));	 width: 100%; align-content: space-between;	">
        <div style="width: 100px;">Tax:</div>
        <div style="text-align: right;">$
        ${totals.tax}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));	 width: 100%; align-content: space-between;	">
        <div style="width: 100px;">Shipping: </div>
        <div style="text-align: right;">
        ${totals.shipping ? ` $${totals.shipping}` : 'Free'}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); width: 100%; align-content: space-between;	">
        <div style="width: 100px;">Total: </div>
        <div style="text-align: right;">
        $ ${totals.total}
        </div>
      </div>
    </div>
  </div>`;

  return body;
};

const companyReceipt = (order) => {
  let body = '';
  let receiptSummary = '';
  for (let i = 0; i < order.cart.cartItems.length; i++) {
    const item = order.cart.cartItems[i];
    receiptSummary += `<div style="display: flex; width: 100%;">
    <div style="width: 70%;">${item.name}</div>
    <div style="width: 20%;">${item.productNum}</div>
    <div style="width: 10%; ${item.quantity <= item.fulfilled ? 'color: lightgray;' : null}">${
      item.quantity <= item.fulfilled ? '0' : '__'
    } / ${item.quantity - item.fulfilled}</div>
    </div>`;
  }

  body += `<div style="min-height:95vh;">
  <div style="max-width: 600px; margin: 0 auto;">
  <div style="background-color: #EDF2F7; height: 55px; width: 100%;">
  <img style=" height: 35px; padding: 10px 25px;" src="https://pagecustomsupplyllc.com/images/icons/pcs-logo.png" alt="Page Custom Supply Logo">
  </div>
  <div style="padding: 0 10px;">
  <h3 style="text-align: center;">
  ${order.businessName ? order.businessName : order.name}
  <br/> Thank you for your recent Purchase!</h3>
    <br/>
    <div><strong>Order: #${order.cart.name ? order.cart.name : order.orderNumber}</strong></div>
    <br/>
    <div style="display:flex;">
      <div style="width: 50%;">
        <div style="color:#005785; font-weight:bold;">Name</div>
        <div>${order.name}</div>
        <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Phone</div>
        <div>${order.phone}</div>
        <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Purchase Order</div>
        <div>${order.purchaseOrder ? order.purchaseOrder : 'None'}</div>
        `;
  if (order.orderName != 'Cart') {
    body += `
    <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Job Name</div>
      <div>${order.orderName}</div>
    `;
  }

  body += `
      </div>
      <div style="">
        <div style="color:#005785; font-weight:bold;">Shipping Address</div>
        <div>${order.shippingAddress}</div>
        <div>${order.shippingCity}</div>
        <div style="display:flex;">
          <div style="width: 50%;">${order.shippingState}</div>
          <div>${order.shippingZip}</div>
        </div>
      </div>
    </div>
    <hr/>
    <div style="padding: 25px 0 25px;">${receiptSummary}</div>
    <hr/>
    `;

  body += `
    <div style="display:flex; width: 100%;">
    `;
  if (order.billingAddress) {
    body += `
      <div style="width: 50%;">
        <div>
        <div style="color:#005785;">Billing Address</div>
        <div>${order.billingAddress}</div>
        <div>${order.billingCity}</div>
        <div style="display:flex;">
          <div style="width: 25%;">${order.billingState}</div>
          <div>${order.billingZip}</div>
        </div>
        </div>
        <div></div>
      </div>
      `;
  }

  body += `
  <div style="text-align: right; width: 49%;">
        ${order.customerNote ? order.customerNote : ''}
      </div>
    </div>
  </div>
  <div style="height: 45px; width: 100%; padding: 10px; text-align:center;">
  Page Custom Supply, LLC
  <br/>
  47 S Orange Street Unit D 3-4 
  <br/>
  Salt Lake City, UT 84116
  <br/>
  (801) 410-4998
  </div>

  </div>
  <style>.text-primary{color: #005785;}.flex{display:flex;}</style>
  </div>`;

  return body;
};

const packingSlip = (order) => {
  let pickSummary = '';
  for (let i = 0; i < order.cart.cartItems.length; i++) {
    const item = order.cart.cartItems[i];
    pickSummary += `<div style="display: flex; width: 100%; margin: 10px 0; font-size: 12px;">
    <div style="width: 30%;">${item.name}</div>
    <div style="width: 15%;">${item.productNum}</div>
    <div style="width: 15%;">${item.binLocation}</div>
    <div style="width: 10%;">${item.stock ? item.stock : 0}</div>

    <div style="width: 15%; ${item.quantity <= item.fulfilled ? 'color: lightgray;' : null}">${
      item.quantity <= item.fulfilled ? '0' : '__'
    } / ${item.quantity - item.fulfilled} ${item.pieceSize ? `(${item.pieceSize})` : ''}</div>

    <div class="margin-right: 5px;">____ &nbsp&nbsp</div> 
    <div>____</div> 
    </div>`;
  }

  const height =
    order.cart.cartItems.length < 25
      ? '95vh'
      : order.cart.cartItems.length < 70
      ? '195vh'
      : '300vh';

  let body = '';
  body += `<div style="min-height: ${height};  font-size: 12px;">
  <h3 style="text-align: center;">
  Order: #${order.cart.name ? order.cart.name : order.orderNumber}, ${
    order.businessName ? order.businessName : order.name
  }. ${order.date.substring(0, 10)}
  </h3>
  <br/><br/>
  <div style="display:flex;">
    <div style="width: 32%;">
      <div style="color:#005785; font-weight:bold;">Name</div>
      <div>${order.name}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Phone</div>
      <div>${order.phone}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Deliver by</div>
      <div>${order.deliveryDate ? order.deliveryDate.substring(0, 10) : null}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Purchase Order</div>
      <div>${order.purchaseOrder ? order.purchaseOrder : 'None'}</div>
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Delivery Method</div>
      <div>${order.shippingMethod == 'call' ? 'Will Call' : order.shippingMethod}</div>
  `;
  if (order.orderName != 'Cart') {
    body += `
      <div style="color:#005785;" style="padding-top: 10px; font-weight:bold;">Job Name</div>
        <div>${order.orderName}</div>
      `;
  }

  body += `
    </div>
    <div style="width: 32%; ">
      <div style="color:#005785; font-weight:bold;">Customer Note:</div>
      ${order.customerNote ? order.customerNote : ''}
      <div style="color:#005785; font-weight:bold;">Delivery Note:</div>
      ${order.deliveryNote ? order.deliveryNote : ''}
    </div>
    <div style="width: 32%;">
      <div style="color:#005785; font-weight:bold;">Shipping Address</div>
      <div>${order.shippingAddress}</div>
      <div>${order.shippingCity}</div>
      <div style="display:flex;">
        <div style="width: 50%;">${order.shippingState}</div>
        <div>${order.shippingZip}</div>
      </div>
    </div>
  </div>
  <hr/>
  <div style="display: flex; width: 100%; margin: 20px 0;">
    <div style="width: 30%;">Name</div>
    <div style="width: 15%;">Product Num</div>
    <div style="width: 15%;">Bin Loc</div>
    <div style="width: 10%;">Stock</div>
    <div style="width: 15%;">Qty</div>
    <div style="width: 10%;">Signatures</div>
    <div></div>
  </div>
  <div style="padding: 0 0 25px 0;">${pickSummary}</div>
  <hr/>
  </div>
  `;
  return body;
};
